define("discourse/plugins/user-autonomy-plugin/discourse/initializers/topic-OP-admin", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/models/topic", "discourse/models/topic-timer", "discourse/plugins/user-autonomy-plugin/discourse/components/topic-op-admin-menu-button"], function (_exports, _ajax, _pluginApi, _topic, _topicTimer, _topicOpAdminMenuButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const pluginId = "topic-OP-admin";
  function init(api) {
    const currentUser = api.getCurrentUser();
    if (!currentUser) {
      return;
    }
    _topic.default.reopenClass({
      setSlowMode(topicId, seconds, enabledUntil) {
        const data = {
          seconds
        };
        data.enabled_until = enabledUntil;
        if (currentUser.canManageTopic) {
          // Discourse default ajax
          return (0, _ajax.ajax)(`/t/${topicId}/slow_mode`, {
            type: "PUT",
            data
          });
        } else {
          data.id = topicId;
          return (0, _ajax.ajax)("/topic_op_admin/update_slow_mode", {
            type: "PUT",
            data
          });
        }
      }
    });
    _topicTimer.default.reopenClass({
      update(topicId, time, basedOnLastPost, statusType, categoryId, durationMinutes) {
        let data = {
          time,
          status_type: statusType
        };
        if (basedOnLastPost) {
          data.based_on_last_post = basedOnLastPost;
        }
        if (categoryId) {
          data.category_id = categoryId;
        }
        if (durationMinutes) {
          data.duration_minutes = durationMinutes;
        }
        if (currentUser.canManageTopic) {
          // Discourse default ajax
          return (0, _ajax.ajax)({
            url: `/t/${topicId}/timer`,
            type: "POST",
            data
          });
        } else {
          data.id = topicId;
          return (0, _ajax.ajax)({
            url: `/topic_op_admin/set_topic_op_timer`,
            type: "POST",
            data
          });
        }
      }
    });
    api.renderInOutlet("timeline-controls-before", _topicOpAdminMenuButton.default);
    api.renderInOutlet("before-topic-progress", _topicOpAdminMenuButton.default);
    api.renderInOutlet("topic-footer-main-buttons-before-create", _topicOpAdminMenuButton.default);
  }
  var _default = _exports.default = {
    name: pluginId,
    initialize(container) {
      if (!container.lookup("service:site-settings").topic_op_admin_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.8.0", init);
    }
  };
});