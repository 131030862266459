define("discourse/plugins/discourse-adplugin/discourse/templates/connectors/discovery-list-container-top/lda-plugin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{ad-slot placement="topic-list-top" category=this.category.slug}}
  */
  {
    "id": "wxXmDIxC",
    "block": "[[[1,[28,[35,0],null,[[\"placement\",\"category\"],[\"topic-list-top\",[30,0,[\"category\",\"slug\"]]]]]]],[],false,[\"ad-slot\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/templates/connectors/discovery-list-container-top/lda-plugin.hbs",
    "isStrictMode": false
  });
});