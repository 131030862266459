define("discourse/plugins/discourse-post-folding/discourse/components/post-menu-folding-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajax, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostMenuFoldingButton extends _component.default {
    // indicates if the button will be prompty displayed or hidden behind the show more button
    static hidden() {
      return true;
    }
    static shouldRender(args) {
      return args.post.post_number !== 1;
    }
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    get folded() {
      return this.args.post.post_folding_status;
    }
    get title() {
      return this.folded ? "discourse_post_folding.expand.title" : "discourse_post_folding.fold.title";
    }
    get icon() {
      return this.folded ? "expand" : "compress";
    }
    toggleFolded() {
      const post = this.args.post;
      (0, _ajax.ajax)(`/discourse-post-folding/status/${post.id}`, {
        type: this.folded ? "DELETE" : "PUT",
        data: {}
      }).then(res => {
        post.set("post_folding_status", res.post_folding_status);
        this.appEvents.trigger("post-stream:refresh", {
          id: post.id
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleFolded", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="discourse_post_folding-fold-btn"
          ...attributes
          @action={{this.toggleFolded}}
          @icon={{this.icon}}
          @title={{this.title}}
        />
      
    */
    {
      "id": "j/1DrKVE",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"discourse_post_folding-fold-btn\"],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"toggleFolded\"]],[30,0,[\"icon\"]],[30,0,[\"title\"]]]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-post-folding/discourse/components/post-menu-folding-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PostMenuFoldingButton;
});