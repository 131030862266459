define("discourse/plugins/user-autonomy-plugin/discourse/components/topic-op-admin-menu-button", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/modal/edit-slow-mode", "discourse/components/modal/edit-topic-timer", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/user-autonomy-plugin/discourse/components/modal/request-op-admin-form", "discourse/plugins/user-autonomy-plugin/discourse/components/modal/set-topic-op-admin-status", "discourse/plugins/user-autonomy-plugin/discourse/components/modal/tell-reason-form", "discourse/plugins/user-autonomy-plugin/discourse/components/modal/topic-op-admin-silence-user-modal", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _editSlowMode, _editTopicTimer, _ajax, _ajaxError, _discourseI18n, _requestOpAdminForm, _setTopicOpAdminStatus, _tellReasonForm, _topicOpAdminSilenceUserModal, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showButton}}
    <span class="topic-OP-admin-menu-button-container">
      <span class="topic-OP-admin-menu-button">
        <DMenu
          @identifier="topic-OP-admin-menu"
          {{!-- @onRegisterApi={{this.onRegisterApi}} --}}
          @modalForMobile={{true}}
          @autofocus={{true}}
          @triggerClass="btn-default btn-icon toggle-OP-admin-menu"
        >
          <:trigger>
            {{d-icon "gear"}}
          </:trigger>
          <:content>
            <DropdownMenu as |dropdown|>
              {{#each this.buttonListGrouped as |button|}}
                {{#if button}}
                  <dropdown.item>
                    <DButton
                      @label={{button.label}}
                      @translatedLabel={{button.translatedLabel}}
                      @icon={{button.icon}}
                      class={{concatClass "btn-transparent" button.className}}
                      @action={{button.action}}
                    />
                  </dropdown.item>
                {{else}}
                  <dropdown.divider />
                {{/if}}
  
              {{/each}}
            </DropdownMenu>
          </:content>
        </DMenu>
      </span>
    </span>
  {{/if}}
  */
  {
    "id": "KzAe/EAj",
    "block": "[[[41,[30,0,[\"showButton\"]],[[[1,\"  \"],[10,1],[14,0,\"topic-OP-admin-menu-button-container\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"topic-OP-admin-menu-button\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@identifier\",\"@modalForMobile\",\"@autofocus\",\"@triggerClass\"],[\"topic-OP-admin-menu\",true,true,\"btn-default btn-icon toggle-OP-admin-menu\"]],[[\"trigger\",\"content\"],[[[[1,\"\\n          \"],[1,[28,[35,4],[\"gear\"],null]],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"buttonListGrouped\"]]],null]],null],null,[[[41,[30,2],[[[1,\"                \"],[8,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n                  \"],[8,[39,9],[[16,0,[28,[37,10],[\"btn-transparent\",[30,2,[\"className\"]]],null]]],[[\"@label\",\"@translatedLabel\",\"@icon\",\"@action\"],[[30,2,[\"label\"]],[30,2,[\"translatedLabel\"]],[30,2,[\"icon\"]],[30,2,[\"action\"]]]],null],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[30,1,[\"divider\"]],null,null,null],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[2]],null],[1,\"          \"]],[1]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"dropdown\",\"button\"],false,[\"if\",\"span\",\"d-menu\",\":trigger\",\"d-icon\",\":content\",\"dropdown-menu\",\"each\",\"-track-array\",\"d-button\",\"concatClass\"]]",
    "moduleName": "discourse/plugins/user-autonomy-plugin/discourse/components/topic-op-admin-menu-button.hbs",
    "isStrictMode": false
  });
  class TopicOpAdminMenuButton extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "updateTrigger", [_tracking.tracked], function () {
      return 1;
    }))();
    #updateTrigger = (() => (dt7948.i(this, "updateTrigger"), void 0))();
    get topic() {
      return this.args.outletArgs?.model ?? this.args.outletArgs?.topic;
    }
    get showButton() {
      return this.buttonList.length > 0;
    }
    get buttonListGrouped() {
      /** @type {Record<string, ButtonItem>} */
      const groups = {};
      for (const btn of this.buttonList) {
        const key = btn.group || "";
        groups[key] ??= [];
        groups[key].push(btn);
      }
      const res = [];
      for (const key of Object.keys(groups)) {
        res.push(...groups[key]);
        res.push(null);
      }
      res.pop();
      return res;
    }
    get buttonList() {
      (function () {})(this.updateTrigger); // Trigger
      /**
       * @typedef {{ label: string, icon: string, class: string, action: ()=>void, group?: string}} ButtonItem
       */
      /** @type {ButtonItem[]} */
      const res = [];
      if (this.currentUser == null || this.topic == null || this.topic.isPrivateMessage || !this.currentUser.can_create_topic) {
        return [];
      }
      if (this.currentUser.can_manipulate_topic_op_adminable) {
        res.push({
          action: this.showSetTopicOpAdminStatus,
          class: "topic-OP-admin-enable-topic-op-admin",
          icon: "gears",
          label: "topic_op_admin.enable_topic_op_admin",
          group: "manipulating"
        });
      }
      if (this.topic.user_id === this.currentUser.id) {
        // Admin actions
        res.push({
          action: this.applyForTopicOpAdmin,
          class: "topic-OP-admin-apply-for-op-admin",
          icon: "envelope-open-text",
          label: "topic_op_admin.apply_for_op_admin",
          group: "manipulating"
        });
        if (this.topic.topic_op_admin_status.can_close) {
          res.push({
            group: "topic",
            action: () => this.performToggle("closed"),
            ...(this.topic.closed ? {
              class: "topic-OP-admin-open",
              icon: "unlock",
              label: "topic.actions.open"
            } : {
              class: "topic-OP-admin-close",
              icon: "lock",
              label: "topic.actions.close"
            })
          });
        }
        if (this.topic.topic_op_admin_status.can_archive) {
          if (!this.topic.isPrivateMessage) {
            res.push({
              class: "topic-OP-admin-archive",
              action: () => this.performToggle("archived"),
              icon: this.topic.archived ? "folder-open" : "folder",
              label: this.topic.archived ? "topic.actions.unarchive" : "topic.actions.archive",
              group: "topic"
            });
          }
        }
        if (this.topic.topic_op_admin_status.can_visible) {
          res.push({
            class: "topic-OP-admin-visible",
            action: () => this.performToggle("visible"),
            icon: this.topic.visible ? "far-eye-slash" : "far-eye",
            label: this.topic.visible ? "topic.actions.invisible" : "topic.actions.visible",
            group: "topic"
          });
        }
        if (this.topic.topic_op_admin_status.can_slow_mode) {
          res.push({
            class: "topic-OP-admin-slow-mode",
            action: this.showTopicSlowModeUpdate,
            icon: "hourglass-start",
            label: "topic.actions.slow_mode",
            group: "time"
          });
        }
        if (this.topic.topic_op_admin_status.can_set_timer) {
          res.push({
            class: "admin-topic-timer-update",
            action: this.showTopicTimerModal,
            icon: "far-clock",
            label: "topic.actions.timed_update",
            group: "time"
          });
        }
        if (this.topic.topic_op_admin_status.can_make_PM) {
          res.push({
            class: "topic-admin-convert",
            action: this.topic.isPrivateMessage ? "topicOPConvertToPublicTopic" // TODO: convert to Public
            : () => this.performToggle("private"),
            icon: this.topic.isPrivateMessage ? "comment" : "envelope",
            label: this.topic.isPrivateMessage ? "topic.actions.make_public" : "topic.actions.make_private",
            group: "staff"
          });
        }
      }
      if (this.topic.user_id === this.currentUser.id && this.topic.topic_op_admin_status.can_silence || this.currentUser.staff) {
        res.push({
          action: this.showTopicOpBanUsersForm,
          class: "topic-OP-admin-silence-user",
          icon: "microphone-slash",
          label: "topic_op_admin.silence_user",
          group: "staff"
        });
      }
      return res;
    }
    showSetTopicOpAdminStatus() {
      const topic = this.topic;
      this.modal.show(_setTopicOpAdminStatus.default, {
        model: {
          topic,
          enables: {
            close: topic.topic_op_admin_status.can_close,
            archive: topic.topic_op_admin_status.can_archive,
            make_PM: topic.topic_op_admin_status.can_make_PM,
            visible: topic.topic_op_admin_status.can_visible,
            slow_mode: topic.topic_op_admin_status.can_slow_mode,
            set_timer: topic.topic_op_admin_status.can_set_timer,
            silence: topic.topic_op_admin_status.can_silence,
            fold_posts: topic.topic_op_admin_status.can_fold_posts
          },
          cb: modal => {
            const new_status = {};
            for (const key of Object.keys(this.topic.topic_op_admin_status)) {
              // "can_open" -> "open"
              new_status[key] = modal.enables[key.slice(4)];
            }
            this.topic.set("topic_op_admin_status", new_status);
            this.updateTrigger = 1;
          }
        }
      });
    }

    /**
     * @param {string} url
     * @param {"POST" | "PUT"} method
     * @param {object} data
     * @param {?string} reason
     * @returns {Promise<void>}
     */
    static #_6 = (() => dt7948.n(this.prototype, "showSetTopicOpAdminStatus", [_object.action]))();
    _send_ajax(url, method, data, reason) {
      data.id = this.topic.id;
      reason ||= (0, _discourseI18n.i18n)("topic_op_admin.default_reason");
      data.reason = reason;
      return (0, _ajax.ajax)(url, {
        method,
        data
      }).then(res => {
        if (!res.success) {
          this.dialog.alert(res.message);
        } else {
          if (data.status) {
            this.topic.toggleProperty(data.status);
          }
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    toggleTopicStatus(key, reason) {
      return this._send_ajax("/topic_op_admin/update_topic_status/", "POST", {
        status: key,
        enabled: !this.topic.get(key)
      }, reason);
    }
    convertTopic(type, reason) {
      return this._send_ajax("/topic_op_admin/topic_op_convert_topic", "PUT", {
        type
      }, reason);
    }

    /** @param {String} name  */
    performToggle(name) {
      const name2handler = {
        closed: "toggleTopicStatus",
        visible: "toggleTopicStatus",
        archived: "toggleTopicStatus",
        private: "convertTopic"
      };
      const fn = this[name2handler[name]].bind(this);
      if (this.siteSettings.topic_op_admin_require_reason_before_action) {
        this.modal.show(_tellReasonForm.default, {
          model: {
            submit: async modal => {
              if (modal.reason === "") {
                this.dialog.alert((0, _discourseI18n.i18n)("topic_op_admin.reason_modal.alert_no_reason"));
              } else {
                await fn(name, modal.reason);
              }
            }
          }
        });
      } else {
        fn(name);
      }
    }
    showTopicSlowModeUpdate() {
      this.modal.show(_editSlowMode.default, {
        model: {
          topic: this.topic
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "showTopicSlowModeUpdate", [_object.action]))();
    showTopicTimerModal() {
      this.modal.show(_editTopicTimer.default, {
        model: {
          topic: this.topic,
          setTopicTimer: v => this.topic.set("topic_timer", v),
          updateTopicTimerProperty: this.updateTopicTimerProperty
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "showTopicTimerModal", [_object.action]))();
    updateTopicTimerProperty(property, value) {
      this.topic.set(`topic_timer.${property}`, value);
    }
    static #_9 = (() => dt7948.n(this.prototype, "updateTopicTimerProperty", [_object.action]))();
    applyForTopicOpAdmin() {
      this.modal.show(_requestOpAdminForm.default, {
        model: {
          topic: this.topic
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "applyForTopicOpAdmin", [_object.action]))();
    showTopicOpBanUsersForm() {
      this.modal.show(_topicOpAdminSilenceUserModal.default, {
        model: {
          topic: this.topic
        }
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "showTopicOpBanUsersForm", [_object.action]))();
  }
  _exports.default = TopicOpAdminMenuButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TopicOpAdminMenuButton);
});