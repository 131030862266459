define("discourse/plugins/discourse-signatures/discourse/initializers/extend-for-signatures", ["exports", "@ember/utils", "discourse/lib/plugin-api", "discourse/widgets/raw-html"], function (_exports, _utils, _pluginApi, _rawHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function attachSignature(api, siteSettings) {
    api.includePostAttributes("user_signature");
    api.decorateWidget("post-contents:after-cooked", dec => {
      const attrs = dec.attrs;
      if ((0, _utils.isEmpty)(attrs.user_signature)) {
        return;
      }
      const currentUser = api.getCurrentUser();
      let enabled;
      if (currentUser) {
        enabled = currentUser.get("custom_fields.see_signatures") ?? siteSettings.signatures_visible_by_default;
      } else {
        enabled = siteSettings.signatures_visible_by_default;
      }
      if (enabled) {
        if (siteSettings.signatures_advanced_mode) {
          return [dec.h("hr"), dec.h("div", new _rawHtml.default({
            html: `<div class='user-signature'>${attrs.user_signature}</div>`
          }))];
        } else {
          if (!attrs.user_signature.match(/^https?:\/\//)) {
            return;
          }
          return [dec.h("hr"), dec.h("img.signature-img", {
            attributes: {
              src: attrs.user_signature
            }
          })];
        }
      }
    });
  }
  function addSetting(api) {
    api.modifyClass("controller:preferences/profile", {
      pluginId: "discourse-signatures",
      actions: {
        save() {
          this.set("model.custom_fields.see_signatures", this.get("model.see_signatures"));
          this.get("saveAttrNames").push("custom_fields");
          this._super();
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "extend-for-signatures",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.signatures_enabled) {
        (0, _pluginApi.withPluginApi)("0.1", api => attachSignature(api, siteSettings));
        (0, _pluginApi.withPluginApi)("0.1", api => addSetting(api, siteSettings));
      }
    }
  };
});