define("discourse/plugins/discourse-category-restrictor/discourse/components/restrictor-manager-button", ["exports", "@ember/service", "@ember/object", "@glimmer/component", "@glimmer/tracking", "discourse/lib/bookmark", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-category-restrictor/discourse/components/modal/category-restrictor-manager", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _service, _object, _component, _tracking, _bookmark, _decorators, _ajax, _ajaxError, _categoryRestrictorManager, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RestrictorManagerButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    showCategoryRestrictorManager() {
      this.modal.show(_categoryRestrictorManager.default, {
        model: {
          category: this.args?.category
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "showCategoryRestrictorManager", [_object.action]))();
    get getLabel() {
      if (this.args.location == "category-custom-security") {
        return "discourse_category_restrictor.button_label";
      } else {
        return "";
      }
    }
    get showButton() {
      // if someone can see this page it's ok anyway
      if (this.args.location == "category-custom-security") {
        return true;
      }
      // show button on category page only for category moderators
      if (this.args.location == "before-create-topic-button") {
        const categoryId = this.args?.category?.id;
        return categoryId && this.currentUser && this.currentUser.category_moderator_for_ids.includes(categoryId);
      }
      return false;
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showButton}}
          <DButton
            @action={{this.showCategoryRestrictorManager}}
            @icon="user-slash"
            @label={{this.getLabel}}
          />
        {{/if}}
      
    */
    {
      "id": "YN0RqkxU",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showButton\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"showCategoryRestrictorManager\"]],\"user-slash\",[30,0,[\"getLabel\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-restrictor/discourse/components/restrictor-manager-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = RestrictorManagerButton;
});