define("discourse/plugins/discourse-adplugin/discourse/templates/connectors/topic-above-suggested/lda-plugin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{ad-slot placement="topic-above-suggested" category=this.model.category.slug}}
  */
  {
    "id": "nGSht92R",
    "block": "[[[1,[28,[35,0],null,[[\"placement\",\"category\"],[\"topic-above-suggested\",[30,0,[\"model\",\"category\",\"slug\"]]]]]]],[],false,[\"ad-slot\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/templates/connectors/topic-above-suggested/lda-plugin.hbs",
    "isStrictMode": false
  });
});