define("discourse/plugins/discourse-post-folding/api-initializers/discourse-post-folding", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/api", "discourse/plugins/discourse-post-folding/discourse/components/post-menu-folding-button"], function (_exports, _ajax, _ajaxError, _api, _postMenuFoldingButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.16.0", api => {
    // api.modifyClass(
    //   "controller:topic",
    //   (Superclass) =>
    //     class extends Superclass {
    //       subscribe() {
    //         super.subscribe(...arguments);
    //         console.log(
    //           "subscribe",
    //           `/discourse-post-folding/topic/${this.model.id}`
    //         );
    //         this.messageBus.subscribe(
    //           `/discourse-post-folding/topic/${this.model.id}`,
    //           this._onPostFoldingMessage
    //         );
    //       }
    //       unsubscribe() {
    //         this.messageBus.unsubscribe(
    //           "/discourse-post-folding/topic/*",
    //           this._onPostFoldingMessage
    //         );
    //         super.unsubscribe(...arguments);
    //       }
    //       _onPostFoldingMessage(msg) {
    //         console.log(msg);
    //         const post = this.get("model.postStream").findLoadedPost(msg.post_id);
    //         post?.set("post_folding_status", msg.post_folding_status);
    //       }
    //     }
    // );

    api.includePostAttributes("post_folding_status");
    api.addPostClassesCallback(attrs => {
      if (attrs.post_folding_status == null) {
        return [];
      } else {
        return ["folded"];
      }
    });
    function shoudRenderPostFoldingButton(post) {
      const currentUser = api.getCurrentUser();
      let canFold = false;
      if (post.post_number === 1) {
        return;
      }
      if (currentUser == null) {
        return;
      }
      canFold ||= currentUser?.can_fold_post;
      canFold ||= post.topic?.topic_op_admin_status?.can_fold_posts && currentUser.id === post.topic.user_id;
      return canFold;
    }
    function makeButton(attrs) {
      if (!shoudRenderPostFoldingButton(attrs)) {
        return;
      }
      const folded = attrs.post_folding_status != null;
      return {
        action: post => {
          if (post.post) {
            post = post.post;
          }
          (0, _ajax.ajax)(`/discourse-post-folding/status/${post.id}`, {
            type: folded ? "DELETE" : "PUT",
            data: {}
          }).then(res => {
            post.set("post_folding_status", res.post_folding_status);
            api.container.lookup("service:app-events").trigger("post-stream:refresh", {
              id: post.id
            });
          }).catch(_ajaxError.popupAjaxError);
        },
        icon: folded ? "expand" : "compress",
        className: "discourse_post_folding-fold-btn",
        label: folded ? "discourse_post_folding.expand.title" : "discourse_post_folding.fold.title",
        position: "second-last-hidden"
      };
    }
    api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          post,
          lastHiddenButtonKey // key of the last hidden button
        }
      } = _ref;
      if (post.canManage || post.canWiki || post.canEditStaffNotes) {
        return;
      }
      if (!shoudRenderPostFoldingButton(post)) {
        return;
      }
      dag.add("post-folding", _postMenuFoldingButton.default, {
        before: lastHiddenButtonKey
      });
    });
    api.addPostAdminMenuButton(attrs => {
      if (attrs.canManage || attrs.canWiki || attrs.canEditStaffNotes) {
        return makeButton(attrs);
      }
    });
  });
});